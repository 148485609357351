<template>
  <div class="fee">
    <el-tabs default-active-key="1">
      <el-tab-pane key="1" label="在线充值">
        <div class="fee-recharge">
          <el-row class="fee-recharge-item">
            <el-col :span="6" class="fee-recharge-item-label">充值企业</el-col>
            <el-col :span="18">云咖信息</el-col>
          </el-row>
          <el-row class="fee-recharge-item">
            <el-col :span="6" class="fee-recharge-item-label">账户余额</el-col>
            <el-col :span="18">{{(999999 / 100) | F1000 }} 元</el-col>
          </el-row>

          <el-row class="fee-recharge-item">
            <el-col :span="6" class="fee-recharge-item-label">充值金额</el-col>
            <el-col :span="12">
              <el-input default-value="100">
                <span slot="addonAfter">元</span>
              </el-input>
            </el-col>
            <el-col :span="4" :offset="1" v-if="ticketModel">
              <el-button type="primary">使用优惠券</el-button>
            </el-col>
          </el-row>


          <el-row class="fee-recharge-item">
            <el-col :span="6" class="fee-recharge-item-label">支付方式</el-col>
            <el-col :span="18" class="fee-pay-content">
              <div class="fee-pay-item" :class="{active: payStyle === 'ali'}" @click="payStyle = 'ali'">
                <i class="iconfont icon-zhifubao" :style="{fontSize: '32px', color: '#027AFF'}"></i>
                <span>支付宝</span>
              </div>

              <div class="fee-pay-item" :class="{active: payStyle === 'wechat'}"  @click="payStyle = 'wechat'">
                <i class="iconfont " :type="'iconweixinzhifu'" :style="{fontSize: '32px', color: '#1AAD19'}"></i>
                <span>微信支付</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="fee-recharge-item pay">
            <el-col :span="18" :offset="6">
              <el-button type="primary" >立即充值</el-button>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane key="2" v-if="ticketModel" label="优惠券">
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
  export default {
    name: 'org-basic-fee',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        payStyle: 'ali',
        ticketModel: true,
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .fee {
    padding: 0 $container-padding;
    padding-top: $middle-space;

    &-recharge {
      width: 45%;
      min-width: 360px;

      &-item {
        margin-bottom: $large-space;

        &-label {
          padding-left: $small-space;
          font-weight: bold;
        }

        .fee-pay-content {
          display: flex;

          .fee-pay-item {
            position: relative;
            border: 1px solid $content-border-color;
            width: 120px;
            text-align: center;
            padding: $small-space 0;
            border-radius: $box-mini-radius;
            margin-right: $middle-space;
            cursor: pointer;

            i{
              margin-right: $small-space;
            }

            span {
              font-weight: 500;
              top: -8px;
              position: relative;
            }
          }

          .active{
            border-color: $theme-color;
          }

          .active:after{
            display: inline-block;
            content: " ";
            position: absolute;
            bottom: 0;
            right: 0;
            height: 12px;
            border-bottom: 13px solid $theme-color;
            border-left: 15px solid transparent;
          }
        }


      }

      .pay{
        padding-top: $large-space;
      }


    }
  }
</style>
